.homepage-landing-box {
	width: 100vw;
	height: 100vh;

	background: #FFF;

	display: grid;

	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
	grid-template-rows: minmax(80px, 200px) minmax(300px, 2fr) minmax(0, 200px);
}

.dark .homepage-landing-box {
	background: #111;
}

.homepage-landing-box .top-bar-container {
	grid-column: 1 / 3;
	grid-column: 1 / 2;
	display: flex;
	flex-direction: column;
}

.homepage-landing-box .top-bar-container {
	align-items: flex-end;
	justify-content: center;
}

.homepage-landing-box .top-bar-container img.logo {
	/* object-fit: contain; */
	height: 140px;
	padding-top: 20px;
	box-sizing: border-box;
}

.homepage-landing-box .left-content-box {
	grid-column: 1 / 2;
}

.homepage-landing-box .right-content-box {
	grid-column: 2 / 3;
	grid-row: 1 / 4;
}

.homepage-landing-box .login-container {
	position: unset !important;
	background: none;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-landing-box .login-container .login-box {
	/* box-shadow: rgba(0, 0, 0, 0.1) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.08) 0px 4.8px 14.4px 0px; */ 
	box-shadow: none !important;
}

.homepage-landing-box .left-content-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.left-content-box .hint-image {
	object-fit: contain;
	max-height: 300px;
	max-width: 450px;
	width: 100%;
}

@media screen and (min-width: 701px) and (max-width: 900px) {
	.homepage-landing-box {
		grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
	}

	.homepage-landing-box .right-content-box .login-container {
		align-items: flex-end;
	}

	.homepage-landing-box .top-bar-container img.logo {
		width: 100%;
		height: 100%;
		object-fit: contain;
		padding-top: 20px;
		padding-left: 20px;
		box-sizing: border-box;
	}
}

@media screen and (max-width: 700px) {
	.homepage-landing-box {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.homepage-landing-box .left-content-box {
		display: none;
	}

	.homepage-landing-box .top-bar-container {
		align-items: flex-start;
		height: 96px;
		padding-left: 20px;
	}

	.homepage-landing-box .login-container {
		align-items: center;
	}

	.homepage-landing-box .top-bar-container img.logo {
		height: 100%;
	}
}

.launchpad {
	display: flex;
	flex: 1;
	max-width: 500px;
	height: 100%;
	padding-left: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.launchpad-buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.launchpad-group {
	display: flex;
	justify-content: flex-end;
}

.launchpad .launchpad-icon {
	margin: 10px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 135px;
	max-width: 135px;
	position: relative;

	border: 1px solid #CCC;
	border-radius: 10px;
	background: #f0f0f0;
}

.dark .launchpad-icon {
	background: transparent !important;
	color: #fff !important;
	fill: #fff !important;
}

.launchpad .launchpad-icon:hover:not(:disabled) {
	cursor: pointer;
	box-shadow: 0 1px 4px -1px rgba(0,0,0,.3);
	outline: 1px solid #ccc;
}

.launchpad .launchpad-icon > i {
	height: 64px;
	line-height: 64px;
	font-size: 56px;
	width: 64px;
}

.launchpad .launchpad-icon > i i {
	width: 56px;
	height: 56px;
}

.launchpad .launchpad-icon span {
	flex: 1;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.launchpad-info-icon {
	position: absolute;
	top: 2px;
	right: 2px;
}