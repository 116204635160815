.login-box-2, .login-box-3 {
	width: 80vw;
	background-color: rgb(255, 255, 255);
	box-sizing: border-box;
	position: relative;
	text-align: left;
	outline: transparent solid 3px;
	min-height: 100px;
	max-height: 100%;
	overflow-y: auto;
	border-radius: 2px;
	max-width: 450px;
}

.login-box-3 {
	max-width: 900px;
	display: grid;
	grid-template-columns: auto auto;
}

.player {
	background-color: #a9a9a9;
	background-image: linear-gradient(to bottom, rgba(27, 27, 27, 0.27) 0%, rgba(27, 27, 27, 0.01) 50%,rgba(0, 0, 0, 0.27) 100%);
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 2px;
	box-sizing: border-box;
	/* max-width: 450px;
	min-width: 100px; */
	/* height: 150px; */
	height: 100%;
	max-height: 100%;
	overflow: hidden;
}

.players.container {
	max-height: 100%;
	height: 142px;
}

.player.flat-ui {
	background-image: linear-gradient(to bottom, rgba(27, 27, 27, 0.01) 0%, rgba(27, 27, 27, 0.05) 100%);
}

.empty-player {
	display: flex;
	min-height: 5.4em;
	justify-content: center;
	position: relative;
	align-items: center;
}

.empty-player .player-label {
	opacity: 0.5;
}

.player.has-cart {
	display: grid;
	grid-template-columns: auto 50px;
	/* grid-template-rows: 3fr 1fr 1.5fr; /* auto 1.3em 39px; */
	grid-template-rows: 3fr 1.3em 2.3em;
	position: relative;
}

.player .waveform {
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column-start: 1;
	grid-column-end: 3;
	position: absolute;
	z-index: 0;
	opacity: 0.15;
	height: calc(100% + 1.3em);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	padding: 0 7px;
	width: 100%;
}

.player .player-cart {
	margin: 10px 0 0 10px;
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column-start: 1;
	grid-column-end: 2;
	position: relative;
	z-index: 1;

	display: block;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.player .player-cart img {
	float: left;
}

.player .player-cart span {
	display: block;
}

.player .volume {
	grid-column-start: 2;
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column-end: 3;
	position: relative;
	z-index: 1;
}

.player .volume .ms-Slider {
	/* height: 80px; */
	height: 100%;
	margin-right: 0px;
	margin-top: -2px;
	float: right;
}

.player .label, .player .controls {
	grid-column: 1 / 3;
}

.player .label {
	margin: 0 3px;
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 1;
}

.player .controls > span > .ms-ProgressIndicator, .player .controls > .ms-ProgressIndicator {
	margin: 0 8px !important;
	border: 2px solid #666 !important;
	width: calc(100% - 16px);
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 4px !important;
}

.ms-ProgressIndicator-itemProgress {
	background-color: transparent;
	padding: 0 !important;
	height: 15px !important;
}

.ms-ProgressIndicator-progressTrack, .ms-ProgressIndicator-progressBar {
	height: 15px !important;
}

.player .controls > span > .ms-ProgressIndicator .qae-icon {
	left: 2px;
}

.player .controls > span > .ms-ProgressIndicator .player-time-icon {
	right: 2px;
}

.player .controls > span > .ms-ProgressIndicator .qae-icon,
.player .controls > span > .ms-ProgressIndicator .player-time-icon {
	position: absolute;
	top: -1px;
	width: 14px;
	height: 14px;
}

..ms-ProgressIndicator {
}

.player .controls .ms-Slider {
	margin: -5px 0 -10px 0;

}

.player .player-cart:hover ~ .overlay {
	display: flex;
}

.player .overlay:hover {
	display: flex;
}

.player.playing:not(:hover) .overlay {
	opacity: 0;
}

.mobile-players .player.playing .overlay {
	opacity: 1 !important;
}

.player .countdown, .dashboard-item .countdown {
	position: absolute;
	top: 8px;
	right: 30px;
	width: 46px;
	height: 46px;
	background: #00ff00;
	border: 1px solid #000;
	border-radius: 10px;
	color: #000;
	text-align: center;
	line-height: 46px;
	font-weight: bold;
	font-size: 19pt;
}

.player .countdown {
	z-index: 9;
}

.dashboard-item .countdown.vu {
	top: -5px;
	right: 10px;
}

.player .countdown.vu {
	right: 50px !important;
}

.player .countdown.large-value, .dashboard-item .countdown.large-value {
	font-size: 14pt;
}

.player .countdown.pre-intro, .dashboard-item .countdown.pre-intro {
	background: #ff0000 !important;
}

.player .countdown.intro, .dashboard-item .countdown.intro {
	background: #00ff00;
	border: 1px solid #000;
}

.player .countdown.segue, .dashboard-item .countdown.segue {
	background: #ffff00;
	border-radius: 100%;
}

.player .countdown.segue.imminent, .dashboard-item .countdown.segue.imminent {
	background: #ff0000;
	border-radius: 100%;
}

.player .overlay, .player .load-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	padding-bottom: 3em;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	opacity: 1;
	transition: opacity 0.1s;
}

.player.flex-1 .overlay {
	z-index: 1;
}

.player .load-overlay {
	z-index: 99;
	pointer-events: all;
	background: rgba(255, 255, 255, 0.8);
	width: 100%;
	height: 100%;
	bottom: unset;
	right: unset;
}

body.dark .player .load-overlay {
	background: rgba(0, 0, 0, 0.7);
}

.player .overlay > * {
	pointer-events: all;
	position: relative;
	z-index: 9;
}

.player .overlay:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	-moz-pointer-events: none;
}

.overlay button {
	border: 2px solid #818181;
	background: rgb(240, 240, 240);
	border-radius: 100px;
	color: #818181;
	fill: #818181;
	height: 38px;
	margin: 0 2px;
	width: 38px;
}

.overlay button.player-primary {
	border-color: #1b6b1b;
	color: #1b6b1b;
	fill: #1b6b1b;
	width: 55px;
	height: 55px;
	font-size: 24px;
	line-height: 55px;
	text-align: center;
	position: relative;
	z-index: 999;
}

.overlay button.colour-play svg {
	height: 30px !important;
	padding-top: 10px;
	padding-left: 2px;
}

.overlay button.colour-pause, .overlay button.fired {
	border-color: #e7c71b;
	color: #e7c71b;
	fill: #e7c71b;
}

.overlay button.player-secondary {
	border-color: #8a1b1b;
	color: #8a1b1b;
	fill: #8a1b1b;
	position: relative;
	z-index: 999;
}

.login-box-2 > .controls {
	padding: 10px;
}

.player .overlay .hide {
	opacity: 1;
	transition: all 0.2s;
}

.player.playing .overlay .hide {
	opacity: 0;
}

.watermark-container {
	position: absolute;
	top: 0;
	bottom: 2.5em;
	right: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.1s;
	opacity: 0.4;

	pointer-events: none;
	-moz-pointer-events: none;
}

.watermark-container > span {
	font-size: 40pt;
	margin-bottom: 8px;
}

.player.playing:not(:hover) .watermark-container {
	opacity: 0.4;
}

.player .title-text {
	font-size: 12pt;
	display: block;
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

.player .artist-text {
	font-size: 10pt;
}

.player .time-text {
	display: block;
	font-size: 10pt;
	/* min-width: 8em; */
	text-align: center;
	padding: 0 5px;
}

.player .label > *:first-child .time-text {
	text-align: left;
}

.player .label > *:last-child .time-text {
	text-align: right;
}

.player-progress-track {
	background: rgba(0, 0, 0, 0.2);
}

.controls .ms-ProgressIndicator-itemProgress {
	position: relative;
	overflow: hidden;
}

.controls .ms-ProgressIndicator-progressTrack {
	position: absolute;
	background-color: rgb(237, 235, 233);
	width: 100%;
}

.controls .ms-ProgressIndicator-progressBar {
	background: #0076d7;
	position: absolute;
}

.vu-inline {
	position: absolute;
	right: 10px;
	width: 48px;
	height: calc(100%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	top: 5px;
}
.vu-inline .vu-box {
	flex: 0;
	border-radius: 1px;
	border: 1px solid rgba(0, 200, 0, 1);
	display: block;
	min-width: 6px;
	margin-right: 3px;
	min-height: 77%;
	height: 77%;
	background: linear-gradient(to bottom, #cc0000 14%,#d0d300 27%,#d0d300 34%,#00cc00 51%,#00cc00 100%);
}

.vu-inline .vu-box .vu-box-el {
	width: 100%;
	background: rgba(0, 0, 0, 0.4);
}

.player-aligned-btn {
	margin: 2px;
	width: 50px;
	border-radius: 5px;
	background: linear-gradient(to bottom, #f4f4f4 0%,#e1e1e1 50%);
	border: 1px solid #707070;
}

.player-aligned-btn:hover {
	background: linear-gradient(to bottom, #ebf7fe 0%,#c1e7fd 50%);
	border-color: #2c628b;
}

.player-aligned-btn:active {
	background: linear-gradient(to bottom, #deeaf0 0%,#a8c8d8 50%);
}

.player-aligned-btn i {
	opacity: 0.7;
}


.fader-vol .ms-Slider .ms-Slider-container {
	height: calc(100% - 5px);
	position: relative;
	right: 5px;
}

.fader-vol ~ span .countdown {
	right: 38px;
}

.fader-vol .ms-Slider-thumb {
	width: 32px;
	border-radius: 4px;
	left: -14px;
}

.fader-vol .ms-Slider-thumb:before, .fader-vol .ms-Slider-thumb:after {
	content: '';
	position: absolute;
	top: 3px;
	border-top: 2px solid #CCC;
	left: 5px;
	width: calc(100% - 10px);
}

.fader-vol .ms-Slider-thumb:after {
	top: 7px;
}