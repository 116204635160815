.waveform-display {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 20px auto;
	grid-template-columns: auto 50px;
}

.waveform-display .waveform-scroll {
	grid-column: 1 / 2;
	grid-row: 1 / 2;
}

.waveform-display .controls {
	grid-column: 2 / 3;
	grid-row: 1 / 3;
}

.waveform-display .viewport {
	grid-column: 1 / 2;
	grid-row: 2 / 3;
	height: calc(100% - 20px) !important;
}