.settings-modal {
	width: 90vw;
	height: 90vh;
	max-width: 1000px;
	position: relative;
	border-radius: 5px;
}

.settings-modal .settings-header {
	position: absolute;
	top: 5px;
	right: 10px;
}

.settings-modal .ms-Modal-scrollableContent {
	height: 100%;
}

.settings-modal .settings-header > * {
	margin: 5px;
}

.settings-modal .settings {
	display: flex;
	align-items: stretch;
	height: 100%;
}

.settings-modal .settings-main {
	background: #f3f2f1;
	padding: 0px 20px 10px;
	flex-basis: 200px;
}

.settings-modal .screen-title {
	line-height: 3em;
}

.settings-modal .settings-child-header {
	border-bottom: 1px solid #ddd;
	line-height: 3em;
}

.settings-modal .settings-child {
	box-sizing: border-box;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 10px 0 0 20px;
	height: 100%;
}

.settings-modal .ms-Nav-compositeLink {
	background-color: transparent;
}

.settings-modal .ms-Label b {
	font-weight: 600;
}

.settings-modal .ms-Nav-compositeLink.is-selected button {
	background: rgb(220, 236, 249) !important;
	font-weight: bold;
}

.settings-modal .settings-child .settings-body {
	flex: 1;
	overflow-y: scroll;
	box-sizing: border-box;
}

.settings-media-type-list > * {
	margin-bottom: 5px;
}

.settings-main nav ul li button > div > i {
	width: 16px;
}

div[name="Media Cache"] .ms-Nav-chevronButton {
	display: none;
}

.settings-modal .ms-Nav-group i.ms-Button-icon {
	width: 16px;
}