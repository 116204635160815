.login-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100000001;
}

.dark .login-box {
	background: #111;
	color: #FFF;
}

.login-box {
	width: 80vw;
	box-shadow: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
	background-color: rgb(255, 255, 255);
	box-sizing: border-box;
	position: relative;
	text-align: left;
	outline: transparent solid 3px;
	min-height: 100px;
	max-height: 100%;
	overflow-y: auto;
	border-radius: 2px;
	max-width: 450px;
	padding: 20px;
}

.login-container .login-status {
	position: absolute;
	top: 0;
	right: 0px;
	text-align: right;
	padding: 10px;
}

.login-status a {
	color: #333;
	text-decoration: none;
}

.login-content {
	padding: 20px;
}

.dialog-title {
	color: #323130;
	display: block;
	font-size: 24px;
	font-weight: 600;
	line-height: 1;
	margin-top: 0;
	margin-bottom: 20px;
}

.login-control-group {
	display: flex;
	align-items: flex-end;
}

.login-control-group > *:first-child {
	flex: 1;
}

.logo {
	display: block;
	height: 70px;
}

.modal-body {

}

.errorTezt a {
	color: #C00 !important;
}
.errorText {
	color: #C00 !important;
	font-weight: bold;
	display: block;
	margin: 5px 0 -10px 0;
}

.build-no {
	float: right;
}

.login-primary-button {
	float: right;
}

.login-secondary-button {
	margin-right: 5px;
}

.login-container .login-footer {
	position: absolute;
	bottom: 0px;
	right: 0px;
	text-align: right;
	padding: 5px 20px;
}

.debug-enable-button {
	opacity: 0.4;
}

input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.status-message {
	display: flex;
	border-radius: 4px;
	align-items: center;
	overflow: hidden;
	text-decoration: #c00 !important;
	color: #000;
	animation: 0.1s fadeIn;
}

@keyframes fadeIn {
	from { opacity: 0; }
	to: { opacity: 1; }
}

.status-icon {
	font-size: 24pt;
}

.status-message .status-text {
	text-decoration: none !important;
}

.status-message-ok {
	color: #666;
	border-color: rgba(0, 0, 0, 0.1);
}

.dark .status-message-ok {
	color: #CCC;
	border-color: rgba(255, 255, 255, 0.3);
}

.status-message-incident {
	background: rgba(255, 170, 0, 0.5);
	border-color: transparent; /* rgba(200, 150, 0, 0.9); */
	color: #843e06;
}

.status-text-head {
	display: block;
	color: #FFF;
	padding: 4px 10px;
	text-align: center;
	background: #999;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.dark .status-text-head {
	background: #FFF;
	color: #000;
	text-shadow: none;
}

.status-text {
	display: block;
	padding: 4px 10px;
}

.dark .status-message-incident {
	background: #e67e22;
	color: #FFF;
}

.status-message-maintenance {
	background: #369;
	color: #FFF;
}

.dark .status-message-maintenance {
	color: #FFF;
}

.status-message-incident .status-text, .status-message-maintenance .status-text {
	font-weight: bold;
}

.status-message-ok .status-text {
	background: #43f077;
	color: #030;
	text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.4);
}

.remember-me {
	margin-top: 10px;
}

.dark .status-message-ok .status-text {
	background: #006d21;
	color: #FFF;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
}

.status-message.status-affect-operational {
	background: #43f077;
	color: #030;
	text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.4);
}

.status-message.status-affect-degraded {
	background: #ffc400;
	color: #FFF;
}

.status-message.status-affect-partial_outage {
	background: #ff8b00;
	color: #FFF;
}

.status-message.status-affect-under_maintenance {
	background: #369;
	color: #FFF;
}

.status-message.status-affect-major_outage {
	background: #de350b;
	color: #FFF;
}

.otp-number-punch {
	display: flex;
	justify-content: center;
}

svg.qr-code-img {
	padding: 20px;
	margin: 0 0 10px 0;
	background: #FFF;
}

.otp-number-punch .ms-TextField {
	width: 40px;
	text-align: center;
	margin-right: 5px;
}

.otp-number-punch .ms-TextField input {
	text-align: center;
	font-size: 20px;
}

.otp-number-punch .ms-TextField .ms-TextField-fieldGroup {
	height: 40px;
}

.otp-number-punch .is-select-all .ms-TextField-fieldGroup::after {
    pointer-events: none;
    content: "";
    position: absolute;
    inset: -1px;
    border: 2px solid rgb(58, 150, 221);
    border-radius: 2px;
}
