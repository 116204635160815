.database-list-item {
	background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
	border: none;
	padding: 10px 0;
	font-size: 12pt;
	cursor: pointer;
	transition: background 0.05s;
	width: 100%;
}

.dark .database-list-item {
	background: linear-gradient(to bottom, #222 0%, #111 100%);
	color: #FFF !important;
	border-color: #777;
}

.database-list-item {
	text-align: left;
	line-height: 48px;
	border: 1px solid #CCC;
	padding: 5px;
	border-radius: 4px;
	margin-bottom: 5px;
	position: relative;
}

.database-list-item .empty-logo {
	display: inline-block;
	width: 48px;
}

.database-list-item img {
	height: 48px;
	float: left;
}

.database-list-item .station-text {
	padding-left: 10px;
}

.database-list-item:hover {
	background: linear-gradient(to bottom, #fff 0%, #f9f9f9 100%);
}

.dark .database-list-item:hover {
	background: linear-gradient(to bottom, #333 0%, #222 100%)
}

.database-list-item:active {
	background: #ccc;
}

.location-text {
	position: relative;
	top: -10px;
}

.station-sub-text {
	position: absolute;
	display: block;
	font-size: 10pt;
	bottom: -10px;
	right: 10px;
}

.login-box.close-btn > button:first-of-type {
	float: right;
	margin-top: -10px;
}