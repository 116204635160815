html body.dark {
	background: #000;
}

body.dark a:not(.ms-Button):link, body.dark a:not(.ms-Button):visited {
	color: #69C;
}

body.dark .ui-ribbon {
	background: linear-gradient(to bottom, #3F4146 0%,#292A2E 100%);
}

.ui-ribbon-section, button {
	fill: #000;
	stroke: #000;
}

body.dark .ui-ribbon-section, body.dark .ui-ribbon-section button, body.dark .ui-ribbon-section button i, body.dark .ui-ribbon-section button i {
	color: #EEE;
	fill: #EEE;
	stroke: #EEE;
}

body.dark .smooth-edit-wave .cursor {
	border-color: #FFF;
}

body.dark .ms-ContextualMenu-link.is-disabled {
	background-color: rgba(255, 255, 255, 0.05);
	color: rgba(255, 255, 255, 0.5) !important;
}

body.dark .ms-ContextualMenu-link.is-disabled *:not(i) {
	color: rgba(255, 255, 255, 0.4) !important;
}

body.dark .smooth-edit-wave .cursor.playhead {
	border-left-color: rgba(100, 255, 100, 0.8);
}

body.dark .media-recorder {
	background-color: rgb(63, 65, 70);
}

body.dark .ui-media-dialog-icon i svg {
	fill: #FFF;
}

body.dark .ui-ribbon-section button:hover,
body.dark .ui-ribbon-section button:hover i,
body.dark .ui-ribbon-section button:hover i,
body.dark .library-search-chev-button {
	color: #FFF;
}

body .ui-ribbon-section button.ui-ribbon-button.pressed.orange-button:hover,
body.dark .ui-ribbon-section button.ui-ribbon-button.pressed.orange-button:hover {
	background-color: #a77000 !important;
	color: #000 !important;
}

body.dark .ui-ribbon-section button.ui-ribbon-button:hover,
body.dark .ui-ribbon-section button.ui-ribbon-action-button:hover,
body.dark .ui-ribbon-section button.ui-ribbon-split-btn-primary + button:hover {
	background: rgba(0, 0, 0, 0.3) !important;
}


body.dark .ui-ribbon-section button.ui-ribbon-button.pressed:hover, body.dark .ui-ribbon-section button.ui-ribbon-action-button.pressed:hover {
	background: rgba(0, 0, 0, 0.6) !important;
}

body.dark .ms-DatePicker-weekday, body.dark .ms-DatePicker-monthAndYear, body.dark .go-to-today, body.dark .ms-DatePicker-currentYear {
	color: #EEE !important;
}

body.dark .ms-DatePicker-day {
	color: #CCC !important;
}

body.dark .ms-DatePicker-prevMonth, body.dark .ms-DatePicker-nextMonth, body.dark .ms-DatePicker-prevYear, body.dark .ms-DatePicker-nextYear, body.dark .ms-DatePicker-monthOption {
	color: #CCC !important;
}

body.dark .login-status a {
	color: #FFF;
}

body.dark .clock-picker svg {
	fill: #FFF;
	stroke: #FFF;
}

body.dark .clock-picker svg path {
	stroke: #FFF;
}

body.dark .login-footer {
	color: #EEE;
}

body.dark .ui-ribbon-section button.is-disabled {
	color: rgba(255, 255, 255, 0.6);
	opacity: 0.5;
}

body.dark .ui-ribbon-section button.is-disabled i {
	opacity: 0.4;
}

body.dark .main-shell > .container, body.dark .main-shell > div > .container {
	background: #333;
}

body.dark .ms-DetailsList, .ui-log-viewport, body.dark .shell-body-column-left .media-list {
	background: #222;
}

body.dark .log-controls button, body.dark .media-list-controls button, body.dark .media-grid-menu button, body.dark .std-btn {
	background: linear-gradient(to bottom, #3F4146 0%,#292A2E 100%);
	stroke: #EEE !important;
	fill: #EEE !important;
	color: #EEE;
	border-color: #999;
	transition: 0.03s all;
}

body.dark .std-btn:disabled {
	background: linear-gradient(to bottom, #3F4146 0%,#292A2E 100%) !important;
	opacity: 0.8;
}

body.dark .log-controls button:hover, body.dark .media-list-controls button:hover, body.dark .media-grid-menu button:hover, body.dark .std-btn:hover {
	/* background: linear-gradient(to bottom, #37393d 0%,#1e1f22 100%); */
	opacity: 0.8 !important;
}

body.dark .log-controls .main-btn i, body.dark .media-list-controls .main-btn i, body.dark .media-grid-menu .main-btn i, body.dark .std-btn i {
	opacity: 0.9;
}

body.dark .ui-log {
	background: #222;
}

body.dark .media-list, body.dark .media-browser, body.dark .media-deck {
	background: #222 !important;
}

body.dark .library-left-tab {
	background: #333;
}

body.dark .oac-menu {
	background: #3F4146;
	color: #FFF;
}

body.dark .qnxt, body.dark .qnxt-state, body.dark .qnxt-event {
	background: #3F4146;
	color: #FFF;
}

body.dark .qnxt-controls .ms-Button {
	background: #F5F5F5;
}
body.dark .qnxt-controls .ms-Button:hover {
	background: #FFF;
}
body.dark .oac-button {
	color: #000 !important;
}

body.dark .media-list-controls {
	color: #fff;
}

body.dark .ui-log-item {
	color: #fff;
	fill: currentColor;
}

body.dark .ui-log-icon .br-svg-icon svg {
	fill: currentColor;
}

body.dark .ms-Button .br-svg-icon svg {
	fill: currentColor !important;
}

body.dark .ui-log-gap-timing, body.dark .ui-log-gap-timing-text {
	background: #3F4146;
	color: #FFF;
	border-color: #777;
}

body.dark .ms-ContextualMenu-icon svg, body.dark i.ms-ContextualMenu-icon {
	fill: currentColor;
	stroke: currentColor;
	color: #FFF;
}

body.dark .ms-ContextualMenu-itemText, body.dark .ms-Nav-compositeLink i {
	color: #FFF;
}

body.dark .ms-Nav-compositeLink:hover i {
	color: rgb(58, 150, 221);
}

body.dark .settings-main {
	background: #292929;
}

body.dark .settings-modal .ms-Nav-compositeLink.is-selected button {
	background: #444 !important;
}

body.dark .favourite-button.favourite-button-empty {
	background: #222;
}

body.dark .favourite-button-empty table td:nth-child(2) {
	color: #FFF;
}

body.dark .media-list-scrollable.favourites-button-container {
	background: #333;
}

body.dark .main-shell {
	background: #111;
}

body.dark .button-overlay-icon .br-svg-icon svg {
	fill: #FFF;
}

body.dark .media-grid-item-selected {
	box-shadow: 0px 0px 0px 2px #FFF;
	border-color: #FFF;
}

body.dark .resizable-window {
	background: #222;
}

body.dark .smooth-edit-main .tab-view-contents, body.dark .news-story-edit-main {
	background: #191d22;
}

body.dark .contact-edit-contain, body.dark .contact-list {
	border-color: #666;
}

body.dark .tab-view-tabbar button {
	background: #111;
}

body.dark .admin-bg {
	background: #333;
}