.br-svg-icon, .br-svg-icon svg {
	width: 100%;
	height: 100%;
}

img.br-svg-icon {
	object-fit: contain;
}


span.br-colour-icon {
	width: 12px;
	height: 12px;
	position: relative;
	top: 1px;
	min-width: 12px;
	border: 1px solid #999;
	border-radius: 2px;
	display: block;
	margin-right: 5px;
}

.dark span.br-colour-icon {
	border-color: #fff;
}
