.qnxt {
	display: grid;
	grid-template-columns: 140px 1fr 100px;
	min-height: 178px;
	border-bottom: 1px solid #555;
	background: #d0d0d0;
	outline: none;
}


.qnxt-image {
	grid-columns: 1 / 2;
}

.qnxt-now {
	grid-column: 2 / 4;
}

.qnxt-now td > span, .qnxt-now td > span * {
	font-weight: bold !important;
}

.qnxt .ui-log-item {
	border-left: none !important;
}

.qnxt-now .ui-log-item {
	/* height: 100%; */
	height: 60px;
}

.qnxt-clock {
	/* grid-row: 1 / 2;
	grid-column: 3 / 4; */
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 16pt;
	padding-right: 10px;
	padding-top: 2px;
	font-variant-numeric: tabular-nums;
}

.qnxt-next {
	grid-column: 2 / 2;
	display: flex;
	height: 88px;
	overflow: hidden;
	flex-direction: column;
}

.qnxt-next .ui-log-item {
	flex: 1;
}

.qnxt-next .ui-log-item td:nth-child(3) {
	line-height: 1em;
}

.qnxt-controls {
	display: flex;
	border-left: 1px solid #777;
}

.qnxt-image {
	grid-row: 1 / 3;
	margin: 2px 4px 2px 2px;
	border: 1px solid #999;
	border-radius: 3px;
	background: #F0F0F0;
	padding: 5px;
}

.qnxt-image img {
	background: #FFF;
	border: 1px solid #AAA;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.qnxt-image-controls {
	grid-column: 1 / 2;
}

.qnxt-image-controls .ms-Button {
	padding: 0;
	line-height: 1em;
	height: 100%;
	flex: 2;
}

.qnxt-hide-image .qnxt-image {
	display: none;
}

.qnxt-hide-image .qnxt-now, .qnxt-hide-image .qnxt-next {
	grid-column: 1 / 3 !important;
}

.qnxt-controls .ms-Button {
	flex: 1;
	height: calc(100% - 1px);
	margin: 1px 2px;
}

.qnxt-image-controls, .qnxt-state, .qnxt-event {
	margin: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.qnxt-event i {
	padding: 0 0 0 5px;
}

.qnxt-state, .qnxt-event {
	border: 1px solid #AAA;
	border-radius: 3px;
	background: #FFF;
	padding: 3px;
	font-size: 9pt;
	text-align: center;
}

.qnxt-state {
	display: flex;
	flex-direction: row;
}

.qnxt-state  .qnxt-col-a table td:nth-child(1) {
	width: 100px;
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

.qnxt-state  .qnxt-col-a table td:nth-child(2) {
	text-align: left;
	min-width: 50px;
	max-width: 80px;
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

.qnxt-state > table > tbody > tr > td {
	width: auto;
}

.qnxt-state .qnxt-col-a, .qnxt-state .qnxt-col-b {
	flex: 0;
}

.qnxt-state .qnxt-col-mid {
	flex: 1;
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

.qnxt-state table > tbody > tr > td:nth-child(3) {
    width: 1%;
    white-space: nowrap;
}

.qnxt-state .qnxt-col-b table {
	table-layout: auto;
}

.qnxt-state .qnxt-col-b table td:nth-child(1) {
	text-align: right;
}

.qnxt-state .qnxt-col-b table td:nth-child(2) {
	min-width: 30px;
	max-width: 80px;
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
	text-align: right;
}

.oac-menu {
	outline: none;
	background: #d0d0d0;
	border-bottom: 1px solid #999;
	text-align: right;
	padding: 0px 5px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.oac-button {
	display: block;
	padding: 4px 10px;
	border-radius: 4px;
	height: 22px !important;
	margin: 1px 0px 1px 0;
	font-size: 9pt;
}

td.underrun, td.underrun + td {
	font-weight: bold;
	color: #C00;
}

.qnxt-callout {
	padding: 5px;
}

.qnxt-callout .ms-Callout-main > span {
	padding: 5px;
	display: inline-block;
	font-size: 9pt;
}

.qnxt .ms-Button.hour-mode {
	display: none;
}

.qnxt .ms-Button.hour-mode img {
	height: 32px;
}

.shell-mobile .qnxt .ms-Button.hour-mode {
	display: block;
}

.shell-mobile .qnxt .ms-Button.power-button {
	display: none;
}

.shell-mobile .qnxt {
	width: 100%;
	height: 100%;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 70px 88px 40px;
}

.shell-mobile .qae-container {
	display: flex;
	flex-direction: column;
}

.shell-mobile .qnxt-now {
	grid-column: 1 / 4;
	height: 100%;
	grid-row: 1 / 2;
	max-height: 100%;
}

.shell-mobile .qnxt-now .ui-log-item {
	height: 100% !important;

}

.shell-mobile .qnxt-next {
	grid-column: 1 / 4;
	grid-row: 2 / 3;
	height: 100%;
}

.shell-mobile .qnxt-image {
	display: none;
}

.shell-mobile .qnxt-controls {
	grid-column: 3 / 4;
	grid-row: 3 / 4;
}

.shell-mobile .qnxt-image-controls {
	grid-column: 1 / 2;
	grid-row: 3 / 4;
}

.shell-mobile .qnxt-state {
	grid-column: 1 / 4 !important;
	grid-row: 4 / 5;
}

.shell-mobile .qnxt-state table td:nth-child(1) table td:nth-child(1) {
	width: 50px;
}

.shell-mobile .qnxt-state > table {
	table-layout: unset;
}

.shell-mobile .qnxt-state table td:nth-child(3) table td:nth-child(1) {
	width: 40px;
}

@media screen and (max-width: 600px) {
	/* .shell-mobile .qnxt-state {
		flex-direction: column;
	}

	.shell-mobile .qnxt-state > table {
		display: block;
	}

	.shell-mobile .qnxt-state > table > tbody > tr {
		display: flex;
		flex-direction: column;
		text-align: center;
	} */


	.qnxt {
		grid-template-columns: 1px 1fr 100px;
	}

	.qnxt-image {
		display: none;
	}

	.dashboard-item-index-0 .playout-time {
		width: unset !important;
	}

	div.dashboard-item .countdown {
		left: 50px;
	}
}

.dashboard-item-info {
	position: relative;
}

.dashboard-item-info > span {
	position: absolute;
	height: 100%;
	top: 0;
	justify-content: center;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	min-width: 100%;
}

.dashboard-item-info > span > span {
	max-width: 100%;
	overflow: hidden;
}
