.station-list-item {
	background: #f0f0f0;
	font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
	border: none;
	padding: 10px 0;
	font-size: 12pt;
	cursor: pointer;
	width: 100%;
}

.station-list {
	max-height: 80vh;
	max-height: calc(90vh - 155px);
	overflow-y: auto;
}

.dark .station-list-item {
	background: linear-gradient(to bottom, #222 0%, #212121 100%);
	color: #FFF !important;
	border-color: #777;
}

.station-list-item {
	text-align: left;
	line-height: 48px;
	border: 1px solid #CCC;
	padding: 5px;
	border-radius: 4px;
	margin-bottom: 5px;
	position: relative;
	overflow: hidden;
}

.ms-List-cell:last-of-type .station-list-item {
	margin-bottom: 0;
}

.station-list-item .station-list-border {
	position: absolute;
	width: 3px;
	background: #94d609;
	top: 5px;
	bottom: 5px;
	float: left;
}

.station-list-item .empty-logo {
	display: inline-block;
	width: 48px;
}

.station-list-item img {
	position: absolute;
	right: -30px;
	top: -30px;
	opacity: 0.25;
	height: 120px;
}

.station-list-item .station-text {
	padding-left: 15px;
}

.station-list-item:hover {
}

.dark .station-list-item:hover {
}

.station-list-item:active, .station-list-item:focus {
	background: #b6d7e4;
	outline: none;
	border-color: #000;
}

.dark .station-list-item:active, .dark .station-list-item:focus {
	background: #415875;
	border-color: #ccc;
}

.location-text {
	position: relative;
	top: -10px;
}

.station-sub-text {
	position: absolute;
	display: block;
	font-size: 10pt;
	bottom: -10px;
	right: 10px;
}

.login-box.close-btn > button:first-of-type {
	float: right;
	margin-top: -10px;
}