.ui-ribbon {
	width: 100%;
	display: flex;
	flex-wrap: 1;
	background: linear-gradient(to bottom, #eeeeee 0%,#d4d4d4 100%);

	outline: none;
}

.ui-ribbon-section {
	flex: 0;
	padding: 0 5px;
	height: 100px;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 10px;
}

.ui-ribbon-section-icons {
	display: flex;
	height: calc(100% - 20px);
	flex-wrap: 0;
	align-items: baseline;
}

.ui-ribbon-button {
	font-size: 100%;
	border: none;
	background: none;
	display: block !important;
	width: 54px;
	padding: 5px 0px 2px !important;
	cursor: pointer;
	text-align: center;
	word-wrap: break-word;
	margin-top: 10px;
}

.ui-ribbon-button.disabled {
	opacity: 0.5;
	pointer-events: none;
	cursor: default;

}

.ui-ribbon-button .ui-ribbon-button-inside {
	width: 100%;
	height: 68px;
}

.ui-ribbon-button .ui-ribbon-button-icon.br-svg-icon {
	height: 32px;
}

.ui-ribbon-col {
	padding: 0px 5px 2px;
	display: flex;
	flex-direction: column;
	align-self: center;
}

.ui-ribbon-col .ms-Button-textContainer * {
	font-size: 12px !important;
	white-space: nowrap;
}

.ui-ribbon-button:hover, .ui-ribbon-button + .ms-Button:hover {
	background: rgb(0, 0, 0, 0.07);
	cursor: pointer;
}

.ui-ribbon-label {
	padding-top: 10px;
	text-align: center;
	white-space: pre;
}


.ui-ribbon-action-button {
	height: auto !important;
	padding: 3px inherit;
	flex: 0;
}

.ui-ribbon-action-button.pressed .ms-Button-flexContainer, .ui-ribbon-button.pressed, .ui-ribbon-button.is-checked, .ui-ribbon-action-button.is-checked {
	background: rgba(0, 0, 0, 0.17) !important;
	/* box-shadow: rgba(0, 0, 0, 0.13) 0px -5px 0px; */ 
}

.ui-ribbon-action-button > .ms-Button-flexContainer {
	padding-top: 1px;
	padding-bottom: 1px;
	margin-top: 2px;
	margin-bottom: 2px;
}

.ui-ribbon-action-button:hover {

}

.ui-ribbon-action-button i {
	height: 12px;
	line-height: 12px;
	font-size: 12px;
}

.icon-red {
	color: #C00 !important;
	-webkit-text-stroke: 1px #633;
}

.icon-green {
	color: #0C0 !important;
	-webkit-text-stroke: 1px #363;
}

.icon-yellow {
	-webkit-text-stroke: 2px #CC0;
	color: #FF0 !important;
}

.MD .ui-ribbon, .SM .ui-ribbon {
	flex-wrap: wrap;
}

.MD .ui-ribbon-section-icons, .SM .ui-ribbon-section-icons {
	display: grid;
	height: calc(100% - 20px);
	flex-wrap: wrap;
	flex-direction: column;

	grid-auto-flow: row;
	grid-auto-rows: minmax(32px, 1fr);
	grid-auto-columns: 1fr;
}

.MD.nowrap .ui-ribbon-section-icons {
	grid-auto-flow: column;
}

.MD.group-of-one .ui-ribbon-section-icons {
	grid-template-columns: 1fr;
}

.MD.group-of-two .ui-ribbon-section-icons, .ui-ribbon-no-label .MD .ui-ribbon-section-icons {
	grid-template-columns: repeat(2, 1fr);
	margin: 5px 0 -5px 0;
}

.MD .ui-ribbon-section-icons.has-col .ui-ribbon-col, .SM .ui-ribbon-section-icons.has-col .ui-ribbon-col {
	grid-row-start: 1;
	grid-row-end: 4;
	grid-column: 2;
}

.MD .ui-ribbon-section-icons {
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: stretch;
}

.MD .ui-ribbon-button .ms-Button-flexContainer {
	width: 100%;
}

.MD .ui-ribbon-button .ui-ribbon-button-inside {
	height: auto;
	display: flex;
	align-items: center;
}

.MD .ui-ribbon-button {
	padding-left: 0px !important;
	margin-top: 2px;
	flex: 1;
	align-self: stretch;
	width: 100% !important;
}

.MD .ui-ribbon-button .ui-ribbon-button-inside i, .MD .ui-ribbon-button .ui-ribbon-button-inside img {
	flex-basis: 30px;
	width: 30px;
	font-size: 16px;
	text-align: left;
}

.MD .ui-ribbon-button .ui-ribbon-button-inside .ui-ribbon-button-icon {
	line-height: 16px;
	/* justify-content: flex-start !important; */ 
	text-align: center;
	justify-content: center;
	display: inline-flex;
	align-self: center;
}

.MD .ui-ribbon-button .ui-ribbon-button-inside .ui-ribbon-button-label {
	flex: 1;
	text-align: left;
}

.MD .ui-ribbon-button, .SM .ui-ribbon-button {
	font-size: 7pt;
	border: none;
	background: none;
	display: block;
	width: auto;
	padding: 5px 5px 2px;
	cursor: pointer;
	text-align: center;
	word-wrap: break-word;
	display: flex;
	flex-direction: row;
}

.MD .ui-ribbon-section, .SM .ui-ribbon-section {
	flex: 1;
}

.MD .ui-ribbon-button-icon.br-svg-icon, .SM .ui-ribbon-button-icon.br-svg-icon {
	height: 16px;
}

.MD .ui-ribbon-button-icon.br-svg-icon svg, .SM .ui-ribbon-button-icon.br-svg-icon svg {
	width: 16px;
}

.MD .ui-ribbon-col, .SM .ui-ribbon-col {
	padding: 0px 1px 2px;
	display: flex;
	flex-direction: column;
}

.SM .ui-ribbon-section {
	height: 50px;
}

.SM .ui-ribbon-button .ui-ribbon-button-inside {
	height: 32px;
}

.SM .ui-ribbon-button-icon {
	font-size: 16px;
	line-height: 16px;
}

.SM .station-info-logo {
	height: 16px;
	width: 16px;
}

.MD .ui-ribbon-button-label {
	white-space: pre;
}


.SM .ui-ribbon-button-label {
	display: none;
}

.SM .ui-ribbon-col > button .ms-Button-textContainer {
	display: none;
}


.ui-ribbon-button.ms-Button {
	height: auto;
	line-height: 1em;
}

.ui-ribbon-button + .ms-Button:not(.ui-ribbon-button) {
	border: none;
	padding: 0;
}

.ui-ribbon-button + .ms-Button:not(.ui-ribbon-button):not(:hover) {
	background: none;
}


.menu-icon-nooutline svg {
	stroke: none !important;
}

.ui-ribbon-button.wrap-text-btn {
	line-height: 1em;
	font-size: 8pt;
}

.menu-inline-block {
	display: inline-block;
	padding: 0px 10px 5px 10px;
}

.ui-ribbon-action-button {
	min-width: 80px;
}

.ui-ribbon-action-button .ui-ribbon-button-inside {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 2px;
}

.ui-ribbon-action-button .ui-ribbon-button-icon.br-svg-icon {
	height: 16px;
}

.ui-ribbon-split-btn-primary + button.ms-Button {
	padding: 0px 0px 15px !important;
	font-size: 12px;
}

.ui-ribbon-split-btn-primary + button.ms-Button .ms-Button-flexContainer {
	display: flex;
	flex-direction: row-reverse;
}

.ui-ribbon-split-btn-primary + button.ms-Button i {
	display: inline;
}

.ui-ribbon-split-btn-primary .ui-ribbon-button-inside {
	height: 32px;
}

.ui-ribbon-split-btn.ui-ribbon-split-vertical-btn > span {
	flex-direction: column;
}

.MD .ui-ribbon-split-btn {
	padding: 5px 0px 2px
}

.MD .ui-ribbon-split-btn.ui-ribbon-split-vertical-btn > span {
	flex-direction: row !important;
}

.MD .ui-ribbon-split-btn.ui-ribbon-split-vertical-btn .ui-ribbon-split-btn-primary {
	padding-right: 0;
}

.MD .ui-ribbon-split-btn.ui-ribbon-split-vertical-btn .ui-ribbon-split-btn-primary + button.ms-Button {
	padding-bottom: 0 !important;
}

.ui-ribbon-no-label .ui-ribbon-col {
	align-self: start;
}

.ui-ribbon-no-label .ui-ribbon-label {
	display: none;
}

.ui-ribbon-no-label .ui-ribbon-section {
	height: 64px;
}

.ui-ribbon-no-label .ui-ribbon-button-inside {
	height: 38px;
}

.ui-ribbon-no-label .ui-ribbon-split-vertical-btn > span {
	flex-direction: row;
}

.ui-ribbon-no-label .ui-ribbon-split-btn .ui-ribbon-button + .ms-Button {
	margin-top: 10px;
	padding-top: 10px !important;
	padding-bottom: 5px;
}

.ui-ribbon-no-label .ui-ribbon-split-vertical-btn .ui-ribbon-split-btn-primary + .ms-Button .ms-Button-textContainer {
	display: none;
}

.ui-ribbon-no-label .ui-ribbon-button-inside .ui-ribbon-button-label {
	display: none;
}

.ui-ribbon-no-label .ui-ribbon-col .ui-ribbon-button-inside .ui-ribbon-button-label {
	display: block !important;
	margin-left: 10px;
}

.ui-ribbon-no-label .station-info-logo {
	height: 32px;
}

.ui-ribbon-no-label .ui-ribbon-section.MD .ui-ribbon-section-icons {
	margin: 0;
}

.ui-ribbon-very-small .ui-ribbon-section {
	height: 32px;
}

.ui-ribbon-very-small .ui-ribbon-section button {
	margin-top: 4px;
	width: 32px;
}

.ui-ribbon-very-small .ui-ribbon-section .ui-ribbon-button-inside {
	height: 24px;
}

.ui-ribbon-very-small .ui-ribbon-section .ui-ribbon-button-icon,body .ui-ribbon .ui-ribbon-section.MD .ui-ribbon-button-icon {
	font-size: 16px !important;
	line-height: 24px !important;
}

.ui-ribbon-very-small .ui-ribbon-section .ui-ribbon-button-icon svg {
	width: 16px;
	height: 16px;
}

.ui-ribbon-very-small .station-info-logo {
	position: relative;
	top: -5px;
}

.ui-ribbon-very-small .ui-ribbon-split-btn > span > .ms-Button {
	height: 31px !important;
}

.ui-ribbon-very-small .ui-ribbon-split-btn > span > .ms-Button + .ms-Button {
	margin-top: 5px !important;
	width: 16px;
}

.ui-ribbon-col .ui-ribbon-button-label {
	display: flex;
	margin-left: 4px;
}
