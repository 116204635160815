.rain .column {
    display: block;
    float: left;
    width: 14px;
    height: 100%;
}
.rain .row {
    display: block;
    height: 18px;
    width: 100%;
}
.rain .row.rotate {
    transform: rotateZ(180deg) translateY(-4px)
}
.rain .row.flip-hor {
    transform: scaleX(-1)
}
.rain .row.flip-ver {
    transform: scaleY(-1) translateY(-4px)
}