body.global-no-drop-cursor {
	cursor: no-drop !important;
}

.storybook-log-floating {
	background: #f3f3f3;
	max-width: 800px;
	width: 80%;
	margin: 0 auto;
	max-height: 300px;
	display: flex;
	flex-direction: column;
}

.controls.container.ui-ribbon {
	overflow-x: auto;
}

.ms-ContextualMenu-item .view-options .ms-Checkbox {
	margin-bottom: 10px;
}

.ui-log-viewport .ms-DetailsRow.ms-FocusZone:focus, .ui-log-viewport .ms-DetailsRow.ms-FocusZone:after {
	outline: 0 !important;
	border: none !important;
}

.ui-log-item:focus {
	outline: none;
}

.ui-log-viewport .ms-DetailsRow.ms-FocusZone::-moz-focus-inner {
	border: 0;
	outline: none;
}

.ui-log-viewport {
	flex: 1;
	height: 100%;
	overflow-y: hidden;
	border-top: 2px solid #666;
	border-sizing: border-box;
	overflow-x: hidden;
}

.ui-log-viewport .ui-log-item {
	/* workaround for zoom abnormalities */
	height: 100%;
}

.ui-log-item {
	width: 100%;
	color: #000;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-top: 1px solid #EEE;
	border-bottom: 1px solid #999;
	display: flex;
	flex-direction: row;
}

.ui-log-item .log-selected-tick {
	width: 16px;
	background: #2797ff;
	border-right: 1px solid #999;
	text-align: center;
}

.ui-log-item .log-selected-tick-checkbox {
	height: 100%;
	line-height: 30px;
	color: #FFF !important;
}

.ui-log-flexbox {
	flex: 1;
}

.ui-log-item > table {
	flex: 1;
}

.log-ui-item {
	background-color: transparent; /* allow anywhere to be dragged */ 
	table-layout: fixed;
	border-collapse: collapse;
	width: 100%;
	position: relative;
}

.ui-log-disabled-time {
	display: block;
	text-align: center;
}

.ui-log-item td.log-col-time {
	padding-left: 10px;
	width: 54px;
	white-space: nowrap;
}

.ui-log-item td.log-col-time.adjust-time-width {
	width: 70px;
}

.ui-log-item td.log-col-type-icon {
	width: 4ch;
	text-align: center;
}

.ui-log-item td.log-col-type-icon .ui-log-icon {
	padding-left: 0;
}

.ui-log-item td.log-col-media-id {
	width: 9ch;
}

.ui-log-item td.log-col-category {
	width: 11ch;
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

.ui-log-item td.log-col-title {
	line-height: 2.3em;
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

.ui-log-item td.log-col-title span {
	text-overflow: ellipsis;
}

.ui-log-item td.log-col-artist {
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

.ui-log-item td.log-col-intro {
	width: 7ch;
}

.ui-log-item td.log-col-playlength {
	width: 9ch;
	text-align: right;
	position: relative;
}

.ui-log-item td.log-col-playlength > span {
	position: absolute;
	right: 0;
	top: calc(50% - 10px);
}

.ui-log-item td.log-col-hook-icon {
	width: 3ch;
	text-align: center;
}

.ui-log-item td.log-col-chain {
	width: 2.9ch;
	text-align: center;
}

.ui-log-item td.log-col-fill {
	width: 12ch;
	font-size: 10.5pt;
	text-align: center;
}

.ui-log-item.is-selected td.log-col-fill a {
	color: #FFF !important;
}

.ui-log-item td:last-child {
	padding-right: 10px;
}

.log-ui-type-hour {
	background-color: linear-gradient(to bottom, #7a8ecc 0%,#1034a4 49%,#1034a4 50%,#2445ac 100%); 
	color: #FFF;
}


.log-ui-type-meta {
	background: #bbbbe6;
}

.log-ui-type-song {
	background: #f4b2b2;
}

.log-ui-type-advert {
	background: #ededb5;
}

.log-ui-type-link {
	background: #d0ffd0;
}

.log-ui-type-voice {
	background: #fafafa;
}

.log-ui-type-time {
	background: #63c967;
}

.log-ui-type-disabled {
	background: #ebebeb !important;
	color: #999 !important;
}

/* .dark .log-ui-type-disabled {
	background: #111 !important;
	color: #999 !important;
} */

.is-selected .log-ui-item {
	background: linear-gradient(to bottom, #73b4e8 0%, #248adc 49%, #077bd8 50%, #1a86db 100%);
	color: #FFF;
}

.is-selected .log-ui-item svg {
	fill: #FFF;
}

.log-ui-item {
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 0.2) 100%);
}

.flat-ui .log-ui-item {
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.07) 1%, rgba(255, 255, 255, 0.01) 49%, rgba(255, 255, 255, 0.07) 100%);
}

.flat-ui.ui-log-item {
	border-top-color: rgba(255, 255, 255, 0.4);
}

.flat-ui.is-selected {
	box-shadow: 0px 1px 0px #000, 0px -1px 0px #000;
	border-top-color: #73b4e8;
	position: relative;
	z-index: 2;
}

.flat-ui.is-selected .log-ui-item {
	/* background: linear-gradient(to bottom, #73b4e8 0%, #248adc 49%, #077bd8 50%,#1a86db 100%); */ 
	background: #1a86db;
	color: #FFF;
}

.is-selected .ui-log-item-overlay {
	color: #000;
}

.is-selected .log-ui-type-disabled {
	color: #666;
}

.ui-log-hour {
	border-left: 5px solid #CCC;
}

.ui-log-hour-live {
	border-left-color: #ffa500;
}


.ui-log-icon {
	padding-top: 3px;
	padding-left: 5px;
}

.ui-log-icon svg {
	height: 16px !important;
	width: 16px;
}

.log-ui-edit td.log-col-playlength {
	color: #F00;
}

.ui-log-item-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 7.5ch;
	line-height: 2.1em;
	font-size: 12pt;
	color: #000;
	text-align: center;
}

.ui-log-item-icon {
	height: 17pt;
	line-height: 2.7em;
}

.ui-log .ms-DetailsRow-cell {
	padding: 0;
	min-height: 0;
}

.ui-log-drag, .ms-FocusZone .is-dropping {
	/* border-top: 3px solid #000; */
	box-shadow: 0px -3px 0px #000 !important;
}


.ui-log-drag * {
}

.storybook-log-row {
	grid-column: 1/3;
	min-height: 200px;
}

.empty {
	height: 2000px;
}

.ui-log-item {
	border-left: 5px solid #ccc;
}

.log-ui-hour-liveassist {
	border-left-color: #ffa500;
}

.log-ui-hour-auto {
	border-left-color: #90ee90;
}

.ui-log {
	display: flex;
	flex-direction: row;
	background: #a9a9a9;
}

.log-controls {
	display: flex;
	flex-direction: column;
	width: 40px;
}

.log-controls button {
	margin: 1px;
	border-radius: 5px;
	background: linear-gradient(to bottom, #f4f4f4 0%,#e1e1e1 50%);
	border: 1px solid #707070;
}

.log-controls button {
	padding: 3px 0;
}

.log-controls button:hover {
	background: linear-gradient(to bottom, #ebf7fe 0%,#c1e7fd 50%);
	border-color: #2c628b;
}

.log-controls button:active {
	background: linear-gradient(to bottom, #deeaf0 0%,#a8c8d8 50%);
}

.log-controls button i {
	opacity: 0.7;
}

.log-controls .main-btn {
	flex: 1;
}

.icon-rotate-90cw {
	transform: rotate(90deg);
}

.icon-rotate-90ccw {
	transform: rotate(-90deg);
}

.ui-log-chain-icon {
	font-size: 14pt;
	margin-right: 5px;
}

.ui-log-icon-base-container {
	position: relative;
}

.ui-log-chain-icon-state {
	position: absolute;
	z-index: 9;
	bottom: 2px;
	right: 4px;
	color: #FFF;
	font-size: 8pt;
	background: #6292f2;
	border: 1px solid #000;
	-webkit-text-stroke: 1px #FFF;
	padding: 1px;
	margin: 0;
}

.ui-log-chain-icon-dead {
	position: relative;
	left: -2px;
}

.ui-log-chain-icon-dead .br-svg-icon, .ui-log-chain-icon-dead .br-svg-icon svg {
	width: 22px;
	height: 22px !important;
}

.ui-log-locked-icon {
	position: absolute;
	z-index: 9;
	bottom: 2px;
	right: 4px;
	fill: #e64a3e !important;
	font-size: 8pt;
}

.ui-log-chain-icon-missing {
	position: absolute;
	z-index: 9;
	bottom: 0px;
	right: 0px;
	fill: #e64a3e !important;
	font-size: 8pt;
}

.is-selected .ui-log-locked-icon svg {
	fill: #e64a3e !important;
}

.ui-ribbon-button-fullwidth {
	width: 70px !important;
	padding: 5px 0px 2px 0 !important;
}

.log-item-playing-state {
	display: inline-block;
	width: 24px;
	font-size: 20px;
	line-height: 14px;
	padding: 0 0 0 2px;
	-webkit-text-stroke: 1px #000;
	position: relative;
	top: 2px;
}

.log-item-playing-state-playing {
	color: #0F0 !important;
}

.log-item-playing-state-cued {
	color: #FF0 !important;
}

button.ui-ribbon-right-justify > * {
	flex-direction: row-reverse;
}

.ui-log-viewport .ms-Viewport {
	position: relative;
	z-index: 2;
}

.ui-log-gap-timing {
	position: absolute;
	background: #999;
	bottom: 2px;
	padding-bottom: 3px;
	height: 100px;
	width: 100%;
	z-index: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.ui-log-gap-timing-text {
	height: 20px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	line-height: 20px;
	font-size: 9pt;
	width: calc(100% - 10px);
	margin-bottom: 0px;
	background: #999;
	text-align: center;
}

.ui-log-item.log-ui-type-script td.log-col-artist, .ui-log-item.log-ui-type-social td.log-col-artist, .ui-log-item.log-ui-type-script td.log-col-artist {
	width: 0 !important;
}

.log-item-autofilled {
	font-style: italic;
}

.log-item-playing-state, .log-item-playing-state * {
	font-style: normal !important;
}

.artist-wrap {
	display: none;
}

.time-icon-contain {
	padding-right: 5px;
}

.ui-log-icon.time-icon {
	color: #000;
	padding-top: 0 !important;
	position: relative;
	top: 2px;
	fill: #000;
}

.ui-log-item.is-selected .ui-log-icon.time-icon {
	color: #FFF;
}


.note-tt-b {

}

.note-tt-b p {
	margin-top: 0;
}

.note-tt-b + .note-tt-b p {
	margin-top: 0.5em;
}

.note-tt-b table {
	vertical-align: center;
}

.log-container-main {
	font-size: 12px;
	position: relative;
	z-index: 1;
}

.double-height .artist-wrap {
	display: block;
	overflow: clip;
}

.double-height .log-ui-item {
	height: 4.4em;
}

.double-height .log-col-title {
	overflow: hidden;
	line-height: 2em !important;
}

.double-height .log-col-artist {
	display: none;
}

.double-height .log-col-type-icon {
	font-size: 32px;
	width: 3ch !important;
}

.double-height .ui-log-icon svg {
	width: 32px !important;
	height: 32px !important;
}