.ui-log-dashboard-player {
	min-width: 45px !important;
}

.ui-log-dashboard-player.dash-play-wide {
	max-width: 130px !important;
	width: 130px !important;
}

.ui-log-dashboard-player-container {
	display: flex;
}

.dashboard-player, .dashboard-player-secondary {
	background: #FFF;
	border: 1px solid #999;
	border-radius: 3px;
	padding: 4px 0px;
	height: 28px !important;
	text-align: center;
	line-height: 0.8em;
	margin-left: -8px;
	margin-top: -2px;
	margin-right: 0px;

	width: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.dashboard-player > span:nth-child(2) {
	border-top: 1px solid #CCC;
	color: #000 !important;
	width: 100%;
}

.dashboard-item-index-0 .dashboard-player {
	height: 38px !important;
}

.dashboard-player-secondary .ui-log-icon {
	padding: 4px 0;
}

.dashboard-player .player-label {
	color: #090;
	font-weight: bold;
	padding: 0;
	margin: 0;
	line-height: 0.9em;
	position: relative;
	top: -3px;
}

.qnxt-now {
	max-height: 63px;
}

.qnxt-now .ui-log-item {
	height: 100%;
}

.qnxt-now .log-ui-item span {
	font-size: 13pt;
}

.ui-log-item.dashboard-item td:nth-child(1) {
	padding-left: 10px;
	max-width: 7ch;
	width: 3ch;
	min-width: 3ch;
}

.ui-log-item.dashboard-item td:nth-child(1).adjust-time-width {
	width: 11ch;
}

.ui-log-item.dashboard-item td:nth-child(2) {
	width: 2.5ch;
	text-align: center;
}

.ui-log-item.dashboard-item td > div > i.ui-log-icon:not(.ui-log-chain-icon) {
	font-size: 16pt;
	display: flex;
	width: 24px;
	height: 24px;
}

.ui-log-item.dashboard-item td > div > i.ui-log-icon:not(.ui-log-chain-icon) svg {
	width: 24px !important;
	height: 24px !important;
}

.dashboard-item-info {
	line-height: 1.6em;
	overflow-x: hidden;
	padding-left: 10px;
	text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
}

.dashboard-item-info:nth-child(2) span span {
	display: flex;
	align-items: center;
}

.dashboard-item-info + td {
	width: 0;
}

.dashboard-item-info span {
	text-overflow: ellipsis;
}

div.dashboard-item .countdown {
	position: relative;
	top: 0;
	left: 80px;
	right: 0;
}

.dash-btn {
	max-width: 70px !important;
	width: 70px !important;
	margin: 5px !important;
	font-size: 8pt !important;
	height: 70px !important;
}

.dash-btn-icon-flex {
	display: flex;
	flex-direction: column;
}

.dashboard-player-controls-callout {
	min-width: 48px;
}

.dashboard-item-index-0 .playout-time {
	width: 160px !important;
	text-align: right;
	padding-right: 10px;
}

.dashboard-item-index-0 .playout-time > span > span, .dashboard-item-index-0 .playout-time > div > span > span {
	font-size: 30pt !important;
}

.playout-time .ms-TooltipHost > span {
	display: block;
	background: transparent;
}

.playout-time .ms-TooltipHost > span > span {
	pointer-events: none;
	/* apparently this breaks the tooltip otherwise.. :^) */
}

.dashboard-item-index-0 .playout-time > div {
	display: block;
}

.ui-dash-icon.ui-log-chain-icon {
	font-size: 24pt;
}

.qnxt-now .ui-dash-icon.ui-log-chain-icon {
	font-size: 30pt;
}
.ui-log-item.dashboard-item .playout-time + td:nth-child(3) {
	width: 0;
}

.ui-log-item.dashboard-item td:nth-child(4):not(.playout-time):not(.dash-chain-icon) {
	display: none;
	overflow-x: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
}

.ui-log-item.dashboard-item td:nth-child(5) {
	width: 4ch;
	text-align: right;
}

.ui-log-item.dashboard-item td:nth-child(6) {
	color: #000 !important;
	width: 5ch;
	text-align: right;
}

.ui-log-item.dashboard-item td.ui-log-icon-base-container {
	width: 2.9ch;
	text-align: center;
}

.dashboard-item {
	min-height: 38px;
}

.dashboard-player-secondary {
	position: relative;
}

.dashboard-player-secondary i {
	position: relative;
	text-shadow: 0px 0px 3px 5px #fff;
	-webkit-text-stroke: 2px #DD0;
	z-index: 10;
}
.dashboard-item-index-0 .dashboard-player-secondary-progress {
	position: absolute;
	top: 50%;
	margin-top: -20px;
	margin-left: -20px;
	left: 50%;
	width: 40px !important;
	height: 40px;
}

.dashboard-item-index-0 .dashboard-player-secondary-progress svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
}

.dashboard-item-index-0 .dashboard-player-secondary-progress svg + svg {
	width: 24px;
	height: 24px;
	left: 8px;
	top: 8px;
}

.dashboard-player-secondary-text {
	position: absolute;
	bottom: -5px;
	right: -5px;
	border: 1px solid #CCC;
	font-size: 8pt;
	padding: 2px 3px;
	background: #EEE;
}

.ui-log-shimmer-overlay {
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(86,153,236,0.8);
	height: 100%;
}

.dashboard-item-info {
	width: unset !important;
}

.ui-log-shimmer-overlay.intro {
	background: rgba(4, 222, 0, 0.8);
}

.ui-log-shimmer-overlay.segue, .ui-log-shimmer-overlay.closing {
	background: rgba(255, 239, 0, 0.8);
}

.ui-log-shimmer-overlay.segue.imminent {
	background: rgba(230, 27, 27, 0.8) !important;
}

.ui-log-shimmer-overlay.finished {
	background: none !important;
}

.dashboard-item.dashboard-item-index-0 .playout-time ~ .ui-log-icon-base-container {
	padding-right: 10px !important;
}


.dashboard-item .playout-time ~ .ui-log-icon-base-container {
	width: 45px !important;
}

.dashboard-item .auto-trim-time {
	color: #F00;
	font-style: italic;
	font-weight: normal;
}

.ui-log-flexbox {
	position: relative;
}

.log-ui-item {
	position: relative;
	z-index: 1;
	height: 100%;
}

.dashboard-player-secondary.hoz {
	flex-direction: row;
	padding-left: 5px;
	padding-right: 5px;
	margin-right: 10px;
	width: 75px;
	height: 28px !important;
	font-size: 9pt;
}

.dashboard-item-index-0 .dashboard-player-secondary {
	padding-left: 5px;
	padding-right: 5px;
	margin-right: 10px;
	width: 40px;
	height: 38px !important;
	font-size: 9pt;
}

.dashboard-player-secondary-hoz-label {
	padding-left: 10px;
}

.dashboard-player + .dashboard-player-secondary {
	margin-left: 2px;
}

.player-label-head {
	font-size: 10px !important;
	position: relative;
	top: -2px;
}

.player-label {
	font-size: 12pt;
}

.player-label .placehold {
	font-size: 10pt;
	color: #AAA;
}
.qnxt-now .dashboard-player .player-label {
	font-size: 18pt !important;
	line-height: 12pt;
}

.shell-mobile .dashboard-item-index-0 .playout-time {
	width: 100px !important;
}

.shell-mobile .dashboard-item-index-0 .playout-time > span > span {
	font-size: 20pt !important;
}

.dashboard-item .countdown {
	z-index: 99;
}
.dashboard-item .time-icon-contain {
	padding-right: 0;
	display: flex !important;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.dashboard-item .stretch-icon {
	width: 26px;
}

.dashboard-item .time-icon svg {
	height: 16px !important;
}

.dashboard-item-index-0 .time-icon svg {
	height: 28px !important;
	width: 28px !important;
}

.dashboard-item-index-0 .stretch-icon {
	width: 32px;
}

.dashboard-item .stretch-icon {

}


.qnxt-state .qnxt-col-a table td:nth-child(2) {
	min-width: 20px;
}

@media screen and (max-width: 1280px) {
	.qnxt-state {
		font-size: 8pt;
	}
}